<template>
  <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
      <template v-slot:error-message>
          <span class="pl-2 text-md">{{ errorMessage }}</span>
      </template>
  </error-toast>
  <main v-show="quotes" class="mx-32 my-10">
    <section class="flex items-center justify-between px-7 py-4 bg-white rounded shadow">
      <h1 class="text-lg font-bold capitalize">Request for quote</h1>
      <div class="flex flex-row-reverse items-start text-sm">
        <!-- <router-link :to="{ name: 'NewQuotation' }" class="px-3.5 py-2 bg-primaryColor rounded text-white capitalize">Send new request</router-link> -->
        <div class="border border-borderColor rounded px-3.5 py-2 relative">
          <div @click="getRFQByStatus" class="flex items-center">
            <span>Sort by:</span>
            <span class="w-12 uppercase px-1">All</span>
            <img src="@/assets/images/chevron-down-icon-grey.svg">
          </div>
          <ul v-if="showOptions" class="w-full absolute top-full right-0 shadow rounded bg-white">
            <li @click="getRFQ(option)" v-for="(option, index) in options" :key="index" class="px-2 py-1 hover:bg-borderColor cursor-pointer capitalize">{{ option }}</li>
          </ul>
        </div>
        <div class="flex items-center mr-4 w-48 border border-borderColor rounded text-xs">
          <datePicker v-model="startDate" class="rounded cursor-pointer" />
          <span class="mx-1 text-fontColor">-</span>
          <datePicker v-model="endDate" class="rounded cursor-pointer" />
        </div>
        <div class="mr-4">
          <input type="text" placeholder="Search..." class="w-56 border border-borderColor rounded p-2">
        </div>
      </div>
    </section>
      <section class="my-6 px-7 py-4 bg-white rounded shadow">
      <div class="expenditure-items-wrapper flex items-center justify-between uppercase text-xs text-alternativeBgColor px-6 tracking-widest font-bold">
        <div style="width: 20%" class="flex items-center px-2"><input type="checkbox" class="mr-2 align-text-bottom">title</div>
        <div class="text-center">EXPENDITURE COUNT</div>
        <div class="text-center">VENDORS COUNT</div>
        <div class="px-2">date</div>
        <div class="px-2">status</div>
        <img src="@/assets/images/eye-icon.svg" alt="eye icon" style="visibility: hidden">
      </div>
      <div v-for="quote in quotes" :key="quote.id" class="expenditure-items-wrapper flex items-center justify-between capitalize my-2.5 py-4 px-6 border border-borderColor rounded text-sm">
        <div style="width: 20%" class="flex items-center px-2"><input type="checkbox" class="mr-2 align-text-bottom">{{ quote.project_name }}</div>
        <div class="text-center">{{ quote.expenditure_request_count }}</div>
        <div class="text-center">{{ quote.vendor_count }}</div>
        <div class="px-2">{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</div>
        <div>
          <div v-if="quote.status.toLowerCase() === 'initiated' || quote.status.toLowerCase() === 'pending'" class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillPendingStatus">
            {{ quote.status }}
          </div>
          <div v-if="quote.status.includes('approved')" class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillApprovedStatus">
            {{ quote.status }}
          </div>
        </div>
        <router-link :to="{ name: 'QuotationView', params: { id: quote.id } }">
          <img src="@/assets/images/eye-icon.svg" alt="eye icon" class="cursor-pointer w-6">
        </router-link>
      </div>
    </section>
  </main>
  <LoadingSpinner v-if="!quotes" />
</template>

<script>
import Request from '@/services/requestHelper'
import moment from 'moment'
import DatePicker from 'vue3-datepicker'

export default {
  name: 'requestForQuotes',
  components: { DatePicker },
  async mounted() {
    this.setPlaceholderValues()
    try {
          const { status, data } = await Request.getRequest('request/quote/submitted/approval', process.env.VUE_APP_PROCUREMENT_BASE_URL)

            if (status >= 200 && status < 300) {
              this.quotes = data.data
            }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
  },
  data() {
    return {
      errorMessage: null,
      quotes: null,
      moment,
      startDate: null,
      endDate: null,
      showOptions: false,
      options: ['all', 'pending', 'initiated', 'approved']
    }
  },
  methods: {
    setPlaceholderValues() {
      const elements = document.querySelectorAll('.v3dp__datepicker')
      elements[0].childNodes[0].setAttribute('placeholder', '12-06-2019')
      elements[1].childNodes[0].setAttribute('placeholder', '24-08-2021')
    },
    getRFQByStatus() {
      this.showOptions = true
    },
    getRFQ(status) {
      console.log(status)
      this.showOptions = false
    }
  }
}
</script>

<style scoped>
  .expenditure-items-wrapper > div {
    width: calc(100% / 6);
  }
</style>
